body {
	overflow-x: hidden;
	background-color: black;
}

.mt-6, .my-6 {
    margin-top: 4rem!important;
}

.mb-6, .my-6 {
    margin-bottom: 4rem!important;
}

.color-nav {
	color: white;
}

.nav-scroller {
	position: relative;
	z-index: 2;
	height: 2.75rem;
	overflow-y: hidden;
}

.nav-scroller .nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	margin-top: -1px;
	overflow-x: auto;
	color: rgba(255, 255, 255, .75);
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
	padding-top: .75rem;
	padding-bottom: .75rem;
	font-size: .875rem;
	color: var(--secondary);
}

.nav-underline .nav-link:hover {
	color: var(--blue);
}

.nav-underline .active {
	font-weight: 500;
	color: var(--gray-dark);
}